@import '../../../../styles/colors';

.root {
  padding-top: 24px;
  text-align: center;
  line-height: 12px;

  p {
    line-height: 18px;
  }
}

.arrowDown {
  transform: scaleY(-1);
  padding-bottom: 144px;
  padding-left: 24px;
}

.emptyImage {
  padding: 24px 0;
}

.down {
  position: absolute;
  right: 32px;
  bottom: 62px;
}

.displayInline {
  display: inline;
}
