.image {
  background: white;
  max-width: 100%;
}

.figure {
  display: flex;
  justify-content: center;
  background: black;
  border: 1px solid black;
  width: 300px;
}

.comparison {
  display: flex;
  gap: 5px;

  > div {
    width: 50%;
  }
}

