@import '../../../styles/colors';

.root {
  background: $darkBackground;
  border-bottom: 1px solid $textLight;
  display: flex;
  left: 0;
  padding: 4px;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 2;
}

.inner {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  float: left;
}

.right {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.sliderArea {
  padding-right: 10px;
}

.select {
  width: 200px;
}

.slider {
  width: 100px;
}

.previewSizeText {
  padding-left: '8px';
}

.selectAll {
  margin-right: 10px;
}

.checkbox {
  margin-right: 8px;
}

.familyGroupsButton {
  margin-left: 8px;
}
