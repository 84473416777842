/* stylelint-disable selector-class-pattern -- Deactivate rule as this changes global
styles that have - character */
@import '~antd/dist/antd.css';
@import './styles/colors';

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-slider-rail {
  background-color: $textLight;
}

.ant-upload.ant-upload-drag {
  width: 100%;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

body {
  background: #fff;
  overflow: hidden;
}

.form-item-horizontal {
  &.ant-form-item {
    display: flex;
    flex-direction: row;
  }
}
