@import '../../../../../styles/colors';

.root {
  padding-top: 12px;
}

.rootAutoTags {
  margin-top: 20px;
}

.tagPlus {
  background: #fff;
  border-style: dashed;
}

.tagInput {
  margin-right: 8px;
  margin-left: 6px;
  vertical-align: top;
  width: 78px;
}

.longTags {
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  font-weight: 600;
}

.removedTag {
  color: $danger;
}

.moveTagRight {
  font-weight: 600;
  color: #8FBEF9;
}

.moveTagLeft {
  font-weight: 600;
  color: $success;
}
