@import '../../styles/colors';

.root {
  padding: 24px 0;
}

.left {
  padding: 12px;
  background-color: $darkBackground;
}

.right {
  padding: 12px;
}

.button {
  margin-right: 12px;
}
