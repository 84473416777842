@import '../../styles/colors';

.root {
  background: #fff;
  min-height: 280px;
}

.box {
  left: 50%;
  padding: 0 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 380px;
}

.title {
  font-weight: bold;
  text-align: center;
}

.subtitle {
  text-align: center;
}

.loginButton {
  width: 100%;
}

.iconInput {
  color: $textDark;
}
