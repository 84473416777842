@import '../../../styles/colors';

.Error {
  background-color: $danger;
}

.card {
  margin: auto;
}

.partialFixButton {
  margin-left: 10px;
}
