@import '../../../styles/colors';

.root {
  background: #fff;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
  padding-bottom: 160px; // We need this to compensate for the fixed header, so when users scroll down everything is visible.
}

.title {
  color: $textMedium;
  font-size: 12px;
  height: 24px;
  line-height: 40px;
  margin-bottom: 0;
  padding-left: 24px;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
}

.loader {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}

.category {
  cursor: pointer;
}

.menuIcon {
  height: 25px;
  margin-right: 5px;
}

.editButton {
  float: right;
  position: absolute;
  right: 0;
  margin-top: 4px;
  span {
    margin-right: 0 !important;
  }
}
