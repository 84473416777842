.error {
  color: #ff4d4f;
}

.warning {
  color: #ffe58f;
}

.success {
  color: #4caf50;
}
