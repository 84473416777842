@import '../../styles/colors';

.draggerArea {
  height: 164px;
  margin: auto;
  width: 164px;
}

.draggerText {
  font-size: 12px;
}

.draggerTextSmall {
  color: $textDark;
  font-size: 10px;
}

.uploadingIcon {
  margin: auto;
}

.image {
  width: 100%;

  svg {
    height: auto;
    width: 100%;
  }
}
