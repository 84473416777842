.root {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.menuLeft {
  float: left;
}

.menuRight {
  float: right;
}

.menuRightItems {
  float: 'left';
  padding-right: '8px';
}
