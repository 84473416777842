@import '../../../styles/colors';

.Error {
  background-color: $danger;
}

.compareSection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.compareSection > div {
  width: 45%;
}

.compareSection p {
  margin-bottom: 0;
}

.button {
  margin-left: 10px;
}
