@import '../../../../styles/colors';

.header {
  height: 48px;
  border-bottom: 1px solid $textLight;

  h2 {
    display: inline;
  }
}

.right {
  float: right;
}

.draggerArea {
  width: 180px;
  height: 180px;

  margin: 20px auto;
}

.checkbox {
  margin-top: 20px;
  text-align: center;
}
