.image {
  width: 64px;
  height: 64px;
  margin: 20px;
}

.card {
  max-width: 150px;
  margin: auto;
  cursor: pointer;
}
