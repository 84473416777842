.root {
  background: #fff;
}

.saveButton {
  float: right;
  margin-top: 24px;
}

.metaTagInput {
  max-width: 450px;
}

textarea {
  &.metaTagInput {
    max-width: 450px;
  }
}

.rowTools {
  margin-bottom: 20px
}
