@import '../../../styles/colors';

.ReindexResultUnindexedFamilies {
  ul {
    list-style-type: none;
    padding-left: 0px;
    text-decoration: underline;
    line-height: 25px;
  }

  p {
    margin-top: 20px;
  }
}

.ReindexResultDetails {
  display: grid;
}

.LogsSection {
  max-height: 400px;
  background-color: $mainBlack;
  color: $mainWhite;
  overflow-y: scroll;
  padding: 8px;

  p {
    margin-bottom: 0;
  }
}
