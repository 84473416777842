@import '../../../styles/colors';

.header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;

  > div {
    display: flex;
    width: 100%;
    gap: 10px;
    //justify-content: center;
  }
}

.content {
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: auto;
  margin-top: 10px;
  padding-bottom: 50px;
}

.root {
  background: #fff;
  padding: 16px 16px 164px; // We need extra space in the bottom to conpensate for the fixed footer.
  width: 100%;
}

.input {
  margin-left: 5px;
}
