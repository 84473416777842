@import '../../styles/colors';

.root {
  height: 48px;
  border-bottom: 1px solid $textLight;

  h2 {
    display: inline;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.button {
  margin-right: 12px;
}
