$textSuperDark: #4e4d4d;
$textDark: #737373;
$textMedium: #999;
$textLight: #cdcdcd;
$darkBackground: #f2f2f2;
$danger: #ff4d4f;
$success: #00a9a9;
$mainBGLighter: #fafafa;
$mainBlack: #000000;
$mainWhite: #ffffff;
