@import '../../../../../styles/colors';

.root {
  background: #fff;
  border-bottom: 1px solid #f0f2f5;
  padding: 16px;
  width: 100%;
}

.col {
  padding: 4px;
}

.iconAction {
  color: #999;
}

.deleteModalIcon {
  font-size: 18px;
  color: $danger;
  padding-right: 6px;
}
