.root {
  width: 150px;
  height: 26px;
  margin: 4px;

  .content {
    position: relative;

    .closeButton {
      position: absolute;
      right: 0;
    }
  }
}
