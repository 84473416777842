@import '../../../styles/colors';

.successMessage {
  color: $success;
  font-weight: bold;
}

.failedMessage {
  color: $danger;
  font-weight: bold;
}
