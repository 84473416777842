@import '../../../../styles/colors';

.root {
  color: $textMedium;
  position: relative;
  padding: 24px;
  p {
    margin-left: 24px;
    display: inline;
    margin-bottom: 18px;
  }
}
