@import '../../../styles/colors';

.draggerArea {
  width: 164px;
  height: 164px;
  margin: auto;
}

.draggerText {
  font-size: 12px;
}

.draggerTextSmall {
  font-size: 10px;
  color: $textDark;
}

.uploadingIcon {
  margin: auto;
}
