@import '../../../styles/colors';

$asideSize: 250px;

.root {
  background: $darkBackground;
  border-top: 1px solid $textLight;
  bottom: 0;
  padding: 8px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.inner {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  float: left;
}

.right {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;

  :not(:last-child) {
    margin-right: 5px;
  }
}

.newCategoryButtonArea {
  padding: 0 16px 0 8px;
  width: $asideSize - 8px;
}

.newSubcategoryButtonArea {
  padding: 0 16px;
  width: $asideSize;
}

.newButton {
  width: 100%;
}

.moveIconsButtonArea {
  margin: 0 12px;
}
