@import '../../styles/colors';

.root {
  background: $darkBackground;
  border-bottom: 1px solid $textLight;
  display: flex;
  padding: 14px 4;
  width: 100%;
  z-index: 2;
}

.inner {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  float: left;
  > * {
    margin-right: 16px;
  }
}

.right {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  > * {
    margin-left: 16px;
  }
}
