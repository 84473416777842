@import '../../../../styles/colors';

.Error {
  background-color: $danger;
}

.card {
  max-width: 150px;
  margin: auto;
}

.ignoredIcon {
  text-decoration-line: line-through;
  text-decoration-color: red;
}