@import '../../../styles/colors';

.deleteWord {
  color: $danger;
  font-weight: bold;
}

.deleteModalIcon {
  font-size: 18px;
  color: $danger;
  padding-right: 6px;
}
