@import '../../../../styles/colors';

.root {
  .resultsList {
    ul {
      list-style-type: none;
      margin-bottom: 0;
      margin-block-start: 0;
      padding-inline-start: 0;

      li {
        padding: 20px 0 0 20px;
      }
    }

    li:nth-child(odd) {
      background-color: #fafafa;
    }

    button {
      margin: auto;
      margin-top: 24px;
    }
  }
}

.originalColor {
  float: left;
  min-width: 65px;
  font-weight: bold;
  line-height: 12px;
}

.newColorInput {
  width: 100px;
}

.firstStep {
  width: 100%;
  text-align: center;
  margin: 60px 0;
  button {
    margin: auto;
  }
}

.buttonsArea {
  text-align: center;
}

.uploadCloudinaryWarning {
  color: $danger;
}

.repaintError {
  margin: 10px;
}
