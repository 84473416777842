.root {
  padding: 24px 0;
}

.space {
  > div {
    margin-bottom: 24px;
  }
}
.left {
  padding: 12px;
}

.right {
  padding: 12px;
}
