.root {
  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .loadingArea {
    padding: 48px;
    text-align: center;
  }

  .draggerArea {
    width: 250px;
    height: 250px;
    margin: auto;

    p {
      padding: 8px 24px;
    }
  }
}
