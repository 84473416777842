@import '../../../styles/colors';

.root {
  background: #fff;
  color: $textMedium;
  position: relative;
}

.invertedArrow {
  transform: scaleX(-1);
  padding: 24px 48px 24px 12px;
}

.rightArrow {
  padding: 24px;
}

.left {
  float: left;
  p {
    position: absolute;
    bottom: 0;
    display: inline;
    margin-bottom: 12px;
  }
}

.right {
  float: right;
  p {
    position: absolute;
    right: 176px;
    bottom: 0;
    margin-bottom: 12px;
  }
}
