.AccountMembersCard {
  ul {
    list-style-type: none;
    padding-left: 0px;
    text-decoration: underline;
    line-height: 25px;
  }
}

.AvailableSeats {
  margin-bottom: 20px;
  margin-top: 20px;
}

.AccountActivatedBy {
  b {
    display: block;
  }

  span {
    text-decoration: underline;
  }
}
