@import '../../../styles/colors';

.root {
  text-align: center;
  margin-top: 48px;

  p {
    display: inline;
    padding: 4px;
  }
}
