@import '../../styles/colors';

.root {
  background: #fff;

  .exportButton {
    margin-left: 24px;
  }

  .waitingTag {
    width: 150px;
    height: 26px;
    margin: 4px;

    .content {
      position: relative;
    }
  }
}
